import { StaticImageData } from 'next/image';
import { AvailableLanguages } from '../redux/appTypes';
import MobileScreenshot1DarkDE from '../../public/statics/images/screenshots/9.2.0/de/mobile-screenshot-1-dark@3x.png';
import MobileScreenshot1LightDE from '../../public/statics/images/screenshots/9.2.0/de/mobile-screenshot-1-light@3x.png';
import MobileScreenshot2DarkDE from '../../public/statics/images/screenshots/9.2.0/de/mobile-screenshot-2-dark@3x.png';
import MobileScreenshot2LightDE from '../../public/statics/images/screenshots/9.2.0/de/mobile-screenshot-2-light@3x.png';
import MobileScreenshot3DarkDE from '../../public/statics/images/screenshots/9.2.0/de/mobile-screenshot-3-dark@3x.png';
import MobileScreenshot3LightDE from '../../public/statics/images/screenshots/9.2.0/de/mobile-screenshot-3-light@3x.png';
import MobileScreenshot4DarkDE from '../../public/statics/images/screenshots/9.2.0/de/mobile-screenshot-4-dark@3x.png';
import MobileScreenshot4LightDE from '../../public/statics/images/screenshots/9.2.0/de/mobile-screenshot-4-light@3x.png';
// import MobileScreenshot5DarkDE from '../../public/statics/images/screenshots/9.2.0/de/mobile-screenshot-5-dark@3x.png';
// import MobileScreenshot5LightDE from '../../public/statics/images/screenshots/9.2.0/de/mobile-screenshot-5-light@3x.png';
import MobileScreenshot6DarkDE from '../../public/statics/images/screenshots/9.2.0/de/mobile-screenshot-6-dark@3x.png';
import MobileScreenshot6LightDE from '../../public/statics/images/screenshots/9.2.0/de/mobile-screenshot-6-light@3x.png';
import MobileScreenshot7DarkDE from '../../public/statics/images/screenshots/9.2.0/de/mobile-screenshot-7-dark@3x.png';
import MobileScreenshot7LightDE from '../../public/statics/images/screenshots/9.2.0/de/mobile-screenshot-7-light@3x.png';
import DesktopScreenshot1DarkDE from '../../public/statics/images/screenshots/9.2.0/de/desktop-screenshot-1-dark@3x.png';
import DesktopScreenshot1LightDE from '../../public/statics/images/screenshots/9.2.0/de/desktop-screenshot-1-light@3x.png';
import DesktopScreenshot2DarkDE from '../../public/statics/images/screenshots/9.2.0/de/desktop-screenshot-2-dark@3x.png';
import DesktopScreenshot2LightDE from '../../public/statics/images/screenshots/9.2.0/de/desktop-screenshot-2-light@3x.png';
import DesktopScreenshot3DarkDE from '../../public/statics/images/screenshots/9.2.0/de/desktop-screenshot-3-dark@3x.png';
import DesktopScreenshot3LightDE from '../../public/statics/images/screenshots/9.2.0/de/desktop-screenshot-3-light@3x.png';
import DesktopScreenshot4DarkDE from '../../public/statics/images/screenshots/9.2.0/de/desktop-screenshot-4-dark@3x.png';
import DesktopScreenshot4LightDE from '../../public/statics/images/screenshots/9.2.0/de/desktop-screenshot-4-light@3x.png';
// import DesktopScreenshot5DarkDE from '../../public/statics/images/screenshots/9.2.0/de/desktop-screenshot-5-dark@3x.png';
// import DesktopScreenshot5LightDE from '../../public/statics/images/screenshots/9.2.0/de/desktop-screenshot-5-light@3x.png';
import DesktopScreenshot6DarkDE from '../../public/statics/images/screenshots/9.2.0/de/desktop-screenshot-6-dark@3x.png';
import DesktopScreenshot6LightDE from '../../public/statics/images/screenshots/9.2.0/de/desktop-screenshot-6-light@3x.png';
import DesktopScreenshot7DarkDE from '../../public/statics/images/screenshots/9.2.0/de/desktop-screenshot-7-dark@3x.png';
import DesktopScreenshot7LightDE from '../../public/statics/images/screenshots/9.2.0/de/desktop-screenshot-7-light@3x.png';
import MobileScreenshot1DarkEN from '../../public/statics/images/screenshots/9.2.0/en/mobile-screenshot-1-dark@3x.png';
import MobileScreenshot1LightEN from '../../public/statics/images/screenshots/9.2.0/en/mobile-screenshot-1-light@3x.png';
import MobileScreenshot2DarkEN from '../../public/statics/images/screenshots/9.2.0/en/mobile-screenshot-2-dark@3x.png';
import MobileScreenshot2LightEN from '../../public/statics/images/screenshots/9.2.0/en/mobile-screenshot-2-light@3x.png';
import MobileScreenshot3DarkEN from '../../public/statics/images/screenshots/9.2.0/en/mobile-screenshot-3-dark@3x.png';
import MobileScreenshot3LightEN from '../../public/statics/images/screenshots/9.2.0/en/mobile-screenshot-3-light@3x.png';
import MobileScreenshot4DarkEN from '../../public/statics/images/screenshots/9.2.0/en/mobile-screenshot-4-dark@3x.png';
import MobileScreenshot4LightEN from '../../public/statics/images/screenshots/9.2.0/en/mobile-screenshot-4-light@3x.png';
// import MobileScreenshot5DarkEN from '../../public/statics/images/screenshots/9.2.0/en/mobile-screenshot-5-dark@3x.png';
// import MobileScreenshot5LightEN from '../../public/statics/images/screenshots/9.2.0/en/mobile-screenshot-5-light@3x.png';
import MobileScreenshot6DarkEN from '../../public/statics/images/screenshots/9.2.0/en/mobile-screenshot-6-dark@3x.png';
import MobileScreenshot6LightEN from '../../public/statics/images/screenshots/9.2.0/en/mobile-screenshot-6-light@3x.png';
import MobileScreenshot7DarkEN from '../../public/statics/images/screenshots/9.2.0/en/mobile-screenshot-7-dark@3x.png';
import MobileScreenshot7LightEN from '../../public/statics/images/screenshots/9.2.0/en/mobile-screenshot-7-light@3x.png';
import DesktopScreenshot1DarkEN from '../../public/statics/images/screenshots/9.2.0/en/desktop-screenshot-1-dark@3x.png';
import DesktopScreenshot1LightEN from '../../public/statics/images/screenshots/9.2.0/en/desktop-screenshot-1-light@3x.png';
import DesktopScreenshot2DarkEN from '../../public/statics/images/screenshots/9.2.0/en/desktop-screenshot-2-dark@3x.png';
import DesktopScreenshot2LightEN from '../../public/statics/images/screenshots/9.2.0/en/desktop-screenshot-2-light@3x.png';
import DesktopScreenshot3DarkEN from '../../public/statics/images/screenshots/9.2.0/en/desktop-screenshot-3-dark@3x.png';
import DesktopScreenshot3LightEN from '../../public/statics/images/screenshots/9.2.0/en/desktop-screenshot-3-light@3x.png';
import DesktopScreenshot4DarkEN from '../../public/statics/images/screenshots/9.2.0/en/desktop-screenshot-4-dark@3x.png';
import DesktopScreenshot4LightEN from '../../public/statics/images/screenshots/9.2.0/en/desktop-screenshot-4-light@3x.png';
// import DesktopScreenshot5DarkEN from '../../public/statics/images/screenshots/9.2.0/en/desktop-screenshot-5-dark@3x.png';
// import DesktopScreenshot5LightEN from '../../public/statics/images/screenshots/9.2.0/en/desktop-screenshot-5-light@3x.png';
import DesktopScreenshot6DarkEN from '../../public/statics/images/screenshots/9.2.0/en/desktop-screenshot-6-dark@3x.png';
import DesktopScreenshot6LightEN from '../../public/statics/images/screenshots/9.2.0/en/desktop-screenshot-6-light@3x.png';
import DesktopScreenshot7DarkEN from '../../public/statics/images/screenshots/9.2.0/en/desktop-screenshot-7-dark@3x.png';
import DesktopScreenshot7LightEN from '../../public/statics/images/screenshots/9.2.0/en/desktop-screenshot-7-light@3x.png';

import MobileScreenshot1DarkES from '../../public/statics/images/screenshots/9.2.0/es/mobile-screenshot-1-dark@3x.png';
import MobileScreenshot1LightES from '../../public/statics/images/screenshots/9.2.0/es/mobile-screenshot-1-light@3x.png';
import MobileScreenshot2DarkES from '../../public/statics/images/screenshots/9.2.0/es/mobile-screenshot-2-dark@3x.png';
import MobileScreenshot2LightES from '../../public/statics/images/screenshots/9.2.0/es/mobile-screenshot-2-light@3x.png';
import MobileScreenshot3DarkES from '../../public/statics/images/screenshots/9.2.0/es/mobile-screenshot-3-dark@3x.png';
import MobileScreenshot3LightES from '../../public/statics/images/screenshots/9.2.0/es/mobile-screenshot-3-light@3x.png';
import MobileScreenshot4DarkES from '../../public/statics/images/screenshots/9.2.0/es/mobile-screenshot-4-dark@3x.png';
import MobileScreenshot4LightES from '../../public/statics/images/screenshots/9.2.0/es/mobile-screenshot-4-light@3x.png';
// import MobileScreenshot5DarkES from '../../public/statics/images/screenshots/9.2.0/es/mobile-screenshot-5-dark@3x.png';
// import MobileScreenshot5LightES from '../../public/statics/images/screenshots/9.2.0/es/mobile-screenshot-5-light@3x.png';
import MobileScreenshot6DarkES from '../../public/statics/images/screenshots/9.2.0/es/mobile-screenshot-6-dark@3x.png';
import MobileScreenshot6LightES from '../../public/statics/images/screenshots/9.2.0/es/mobile-screenshot-6-light@3x.png';
import MobileScreenshot7DarkES from '../../public/statics/images/screenshots/9.2.0/es/mobile-screenshot-7-dark@3x.png';
import MobileScreenshot7LightES from '../../public/statics/images/screenshots/9.2.0/es/mobile-screenshot-7-light@3x.png';
import DesktopScreenshot1DarkES from '../../public/statics/images/screenshots/9.2.0/es/desktop-screenshot-1-dark@3x.png';
import DesktopScreenshot1LightES from '../../public/statics/images/screenshots/9.2.0/es/desktop-screenshot-1-light@3x.png';
import DesktopScreenshot2DarkES from '../../public/statics/images/screenshots/9.2.0/es/desktop-screenshot-2-dark@3x.png';
import DesktopScreenshot2LightES from '../../public/statics/images/screenshots/9.2.0/es/desktop-screenshot-2-light@3x.png';
import DesktopScreenshot3DarkES from '../../public/statics/images/screenshots/9.2.0/es/desktop-screenshot-3-dark@3x.png';
import DesktopScreenshot3LightES from '../../public/statics/images/screenshots/9.2.0/es/desktop-screenshot-3-light@3x.png';
import DesktopScreenshot4DarkES from '../../public/statics/images/screenshots/9.2.0/es/desktop-screenshot-4-dark@3x.png';
import DesktopScreenshot4LightES from '../../public/statics/images/screenshots/9.2.0/es/desktop-screenshot-4-light@3x.png';
// import DesktopScreenshot5DarkES from '../../public/statics/images/screenshots/9.2.0/es/desktop-screenshot-5-dark@3x.png';
// import DesktopScreenshot5LightES from '../../public/statics/images/screenshots/9.2.0/es/desktop-screenshot-5-light@3x.png';
import DesktopScreenshot6DarkES from '../../public/statics/images/screenshots/9.2.0/es/desktop-screenshot-6-dark@3x.png';
import DesktopScreenshot6LightES from '../../public/statics/images/screenshots/9.2.0/es/desktop-screenshot-6-light@3x.png';
import DesktopScreenshot7DarkES from '../../public/statics/images/screenshots/9.2.0/es/desktop-screenshot-7-dark@3x.png';
import DesktopScreenshot7LightES from '../../public/statics/images/screenshots/9.2.0/es/desktop-screenshot-7-light@3x.png';
import MobileScreenshot1DarkFR from '../../public/statics/images/screenshots/9.2.0/fr/mobile-screenshot-1-dark@3x.png';
import MobileScreenshot1LightFR from '../../public/statics/images/screenshots/9.2.0/fr/mobile-screenshot-1-light@3x.png';
import MobileScreenshot2DarkFR from '../../public/statics/images/screenshots/9.2.0/fr/mobile-screenshot-2-dark@3x.png';
import MobileScreenshot2LightFR from '../../public/statics/images/screenshots/9.2.0/fr/mobile-screenshot-2-light@3x.png';
import MobileScreenshot3DarkFR from '../../public/statics/images/screenshots/9.2.0/fr/mobile-screenshot-3-dark@3x.png';
import MobileScreenshot3LightFR from '../../public/statics/images/screenshots/9.2.0/fr/mobile-screenshot-3-light@3x.png';
import MobileScreenshot4DarkFR from '../../public/statics/images/screenshots/9.2.0/fr/mobile-screenshot-4-dark@3x.png';
import MobileScreenshot4LightFR from '../../public/statics/images/screenshots/9.2.0/fr/mobile-screenshot-4-light@3x.png';
// import MobileScreenshot5DarkFR from '../../public/statics/images/screenshots/9.2.0/fr/mobile-screenshot-5-dark@3x.png';
// import MobileScreenshot5LightFR from '../../public/statics/images/screenshots/9.2.0/fr/mobile-screenshot-5-light@3x.png';
import MobileScreenshot6DarkFR from '../../public/statics/images/screenshots/9.2.0/fr/mobile-screenshot-6-dark@3x.png';
import MobileScreenshot6LightFR from '../../public/statics/images/screenshots/9.2.0/fr/mobile-screenshot-6-light@3x.png';
import MobileScreenshot7DarkFR from '../../public/statics/images/screenshots/9.2.0/fr/mobile-screenshot-7-dark@3x.png';
import MobileScreenshot7LightFR from '../../public/statics/images/screenshots/9.2.0/fr/mobile-screenshot-7-light@3x.png';
import DesktopScreenshot1DarkFR from '../../public/statics/images/screenshots/9.2.0/fr/desktop-screenshot-1-dark@3x.png';
import DesktopScreenshot1LightFR from '../../public/statics/images/screenshots/9.2.0/fr/desktop-screenshot-1-light@3x.png';
import DesktopScreenshot2DarkFR from '../../public/statics/images/screenshots/9.2.0/fr/desktop-screenshot-2-dark@3x.png';
import DesktopScreenshot2LightFR from '../../public/statics/images/screenshots/9.2.0/fr/desktop-screenshot-2-light@3x.png';
import DesktopScreenshot3DarkFR from '../../public/statics/images/screenshots/9.2.0/fr/desktop-screenshot-3-dark@3x.png';
import DesktopScreenshot3LightFR from '../../public/statics/images/screenshots/9.2.0/fr/desktop-screenshot-3-light@3x.png';
import DesktopScreenshot4DarkFR from '../../public/statics/images/screenshots/9.2.0/fr/desktop-screenshot-4-dark@3x.png';
import DesktopScreenshot4LightFR from '../../public/statics/images/screenshots/9.2.0/fr/desktop-screenshot-4-light@3x.png';
// import DesktopScreenshot5DarkFR from '../../public/statics/images/screenshots/9.2.0/fr/desktop-screenshot-5-dark@3x.png';
// import DesktopScreenshot5LightFR from '../../public/statics/images/screenshots/9.2.0/fr/desktop-screenshot-5-light@3x.png';
import DesktopScreenshot6DarkFR from '../../public/statics/images/screenshots/9.2.0/fr/desktop-screenshot-6-dark@3x.png';
import DesktopScreenshot6LightFR from '../../public/statics/images/screenshots/9.2.0/fr/desktop-screenshot-6-light@3x.png';
import DesktopScreenshot7DarkFR from '../../public/statics/images/screenshots/9.2.0/fr/desktop-screenshot-7-dark@3x.png';
import DesktopScreenshot7LightFR from '../../public/statics/images/screenshots/9.2.0/fr/desktop-screenshot-7-light@3x.png';
import MobileScreenshot1DarkIT from '../../public/statics/images/screenshots/9.2.0/it/mobile-screenshot-1-dark@3x.png';
import MobileScreenshot1LightIT from '../../public/statics/images/screenshots/9.2.0/it/mobile-screenshot-1-light@3x.png';
import MobileScreenshot2DarkIT from '../../public/statics/images/screenshots/9.2.0/it/mobile-screenshot-2-dark@3x.png';
import MobileScreenshot2LightIT from '../../public/statics/images/screenshots/9.2.0/it/mobile-screenshot-2-light@3x.png';
import MobileScreenshot3DarkIT from '../../public/statics/images/screenshots/9.2.0/it/mobile-screenshot-3-dark@3x.png';
import MobileScreenshot3LightIT from '../../public/statics/images/screenshots/9.2.0/it/mobile-screenshot-3-light@3x.png';
import MobileScreenshot4DarkIT from '../../public/statics/images/screenshots/9.2.0/it/mobile-screenshot-4-dark@3x.png';
import MobileScreenshot4LightIT from '../../public/statics/images/screenshots/9.2.0/it/mobile-screenshot-4-light@3x.png';
// import MobileScreenshot5DarkIT from '../../public/statics/images/screenshots/9.2.0/it/mobile-screenshot-5-dark@3x.png';
// import MobileScreenshot5LightIT from '../../public/statics/images/screenshots/9.2.0/it/mobile-screenshot-5-light@3x.png';
import MobileScreenshot6DarkIT from '../../public/statics/images/screenshots/9.2.0/it/mobile-screenshot-6-dark@3x.png';
import MobileScreenshot6LightIT from '../../public/statics/images/screenshots/9.2.0/it/mobile-screenshot-6-light@3x.png';
import MobileScreenshot7DarkIT from '../../public/statics/images/screenshots/9.2.0/it/mobile-screenshot-7-dark@3x.png';
import MobileScreenshot7LightIT from '../../public/statics/images/screenshots/9.2.0/it/mobile-screenshot-7-light@3x.png';
import DesktopScreenshot1DarkIT from '../../public/statics/images/screenshots/9.2.0/it/desktop-screenshot-1-dark@3x.png';
import DesktopScreenshot1LightIT from '../../public/statics/images/screenshots/9.2.0/it/desktop-screenshot-1-light@3x.png';
import DesktopScreenshot2DarkIT from '../../public/statics/images/screenshots/9.2.0/it/desktop-screenshot-2-dark@3x.png';
import DesktopScreenshot2LightIT from '../../public/statics/images/screenshots/9.2.0/it/desktop-screenshot-2-light@3x.png';
import DesktopScreenshot3DarkIT from '../../public/statics/images/screenshots/9.2.0/it/desktop-screenshot-3-dark@3x.png';
import DesktopScreenshot3LightIT from '../../public/statics/images/screenshots/9.2.0/it/desktop-screenshot-3-light@3x.png';
import DesktopScreenshot4DarkIT from '../../public/statics/images/screenshots/9.2.0/it/desktop-screenshot-4-dark@3x.png';
import DesktopScreenshot4LightIT from '../../public/statics/images/screenshots/9.2.0/it/desktop-screenshot-4-light@3x.png';
// import DesktopScreenshot5DarkIT from '../../public/statics/images/screenshots/9.2.0/it/desktop-screenshot-5-dark@3x.png';
// import DesktopScreenshot5LightIT from '../../public/statics/images/screenshots/9.2.0/it/desktop-screenshot-5-light@3x.png';
import DesktopScreenshot6DarkIT from '../../public/statics/images/screenshots/9.2.0/it/desktop-screenshot-6-dark@3x.png';
import DesktopScreenshot6LightIT from '../../public/statics/images/screenshots/9.2.0/it/desktop-screenshot-6-light@3x.png';
import DesktopScreenshot7DarkIT from '../../public/statics/images/screenshots/9.2.0/it/desktop-screenshot-7-dark@3x.png';
import DesktopScreenshot7LightIT from '../../public/statics/images/screenshots/9.2.0/it/desktop-screenshot-7-light@3x.png';

import MobileScreenshot1DarkNL from '../../public/statics/images/screenshots/9.2.0/nl/mobile-screenshot-1-dark@3x.png';
import MobileScreenshot1LightNL from '../../public/statics/images/screenshots/9.2.0/nl/mobile-screenshot-1-light@3x.png';
import MobileScreenshot2DarkNL from '../../public/statics/images/screenshots/9.2.0/nl/mobile-screenshot-2-dark@3x.png';
import MobileScreenshot2LightNL from '../../public/statics/images/screenshots/9.2.0/nl/mobile-screenshot-2-light@3x.png';
import MobileScreenshot3DarkNL from '../../public/statics/images/screenshots/9.2.0/nl/mobile-screenshot-3-dark@3x.png';
import MobileScreenshot3LightNL from '../../public/statics/images/screenshots/9.2.0/nl/mobile-screenshot-3-light@3x.png';
import MobileScreenshot4DarkNL from '../../public/statics/images/screenshots/9.2.0/nl/mobile-screenshot-4-dark@3x.png';
import MobileScreenshot4LightNL from '../../public/statics/images/screenshots/9.2.0/nl/mobile-screenshot-4-light@3x.png';
// import MobileScreenshot5DarkNL from '../../public/statics/images/screenshots/9.2.0/nl/mobile-screenshot-5-dark@3x.png';
// import MobileScreenshot5LightNL from '../../public/statics/images/screenshots/9.2.0/nl/mobile-screenshot-5-light@3x.png';
import MobileScreenshot6DarkNL from '../../public/statics/images/screenshots/9.2.0/nl/mobile-screenshot-6-dark@3x.png';
import MobileScreenshot6LightNL from '../../public/statics/images/screenshots/9.2.0/nl/mobile-screenshot-6-light@3x.png';
import MobileScreenshot7DarkNL from '../../public/statics/images/screenshots/9.2.0/nl/mobile-screenshot-7-dark@3x.png';
import MobileScreenshot7LightNL from '../../public/statics/images/screenshots/9.2.0/nl/mobile-screenshot-7-light@3x.png';
import DesktopScreenshot1DarkNL from '../../public/statics/images/screenshots/9.2.0/nl/desktop-screenshot-1-dark@3x.png';
import DesktopScreenshot1LightNL from '../../public/statics/images/screenshots/9.2.0/nl/desktop-screenshot-1-light@3x.png';
import DesktopScreenshot2DarkNL from '../../public/statics/images/screenshots/9.2.0/nl/desktop-screenshot-2-dark@3x.png';
import DesktopScreenshot2LightNL from '../../public/statics/images/screenshots/9.2.0/nl/desktop-screenshot-2-light@3x.png';
import DesktopScreenshot3DarkNL from '../../public/statics/images/screenshots/9.2.0/nl/desktop-screenshot-3-dark@3x.png';
import DesktopScreenshot3LightNL from '../../public/statics/images/screenshots/9.2.0/nl/desktop-screenshot-3-light@3x.png';
import DesktopScreenshot4DarkNL from '../../public/statics/images/screenshots/9.2.0/nl/desktop-screenshot-4-dark@3x.png';
import DesktopScreenshot4LightNL from '../../public/statics/images/screenshots/9.2.0/nl/desktop-screenshot-4-light@3x.png';
// import DesktopScreenshot5DarkNL from '../../public/statics/images/screenshots/9.2.0/nl/desktop-screenshot-5-dark@3x.png';
// import DesktopScreenshot5LightNL from '../../public/statics/images/screenshots/9.2.0/nl/desktop-screenshot-5-light@3x.png';
import DesktopScreenshot6DarkNL from '../../public/statics/images/screenshots/9.2.0/nl/desktop-screenshot-6-dark@3x.png';
import DesktopScreenshot6LightNL from '../../public/statics/images/screenshots/9.2.0/nl/desktop-screenshot-6-light@3x.png';
import DesktopScreenshot7DarkNL from '../../public/statics/images/screenshots/9.2.0/nl/desktop-screenshot-7-dark@3x.png';
import DesktopScreenshot7LightNL from '../../public/statics/images/screenshots/9.2.0/nl/desktop-screenshot-7-light@3x.png';

import MobileScreenshot1DarkPL from '../../public/statics/images/screenshots/9.2.0/pl/mobile-screenshot-1-dark@3x.png';
import MobileScreenshot1LightPL from '../../public/statics/images/screenshots/9.2.0/pl/mobile-screenshot-1-light@3x.png';
import MobileScreenshot2DarkPL from '../../public/statics/images/screenshots/9.2.0/pl/mobile-screenshot-2-dark@3x.png';
import MobileScreenshot2LightPL from '../../public/statics/images/screenshots/9.2.0/pl/mobile-screenshot-2-light@3x.png';
import MobileScreenshot3DarkPL from '../../public/statics/images/screenshots/9.2.0/pl/mobile-screenshot-3-dark@3x.png';
import MobileScreenshot3LightPL from '../../public/statics/images/screenshots/9.2.0/pl/mobile-screenshot-3-light@3x.png';
import MobileScreenshot4DarkPL from '../../public/statics/images/screenshots/9.2.0/pl/mobile-screenshot-4-dark@3x.png';
import MobileScreenshot4LightPL from '../../public/statics/images/screenshots/9.2.0/pl/mobile-screenshot-4-light@3x.png';
// import MobileScreenshot5DarkPL from '../../public/statics/images/screenshots/9.2.0/pl/mobile-screenshot-5-dark@3x.png';
// import MobileScreenshot5LightPL from '../../public/statics/images/screenshots/9.2.0/pl/mobile-screenshot-5-light@3x.png';
import MobileScreenshot6DarkPL from '../../public/statics/images/screenshots/9.2.0/pl/mobile-screenshot-6-dark@3x.png';
import MobileScreenshot6LightPL from '../../public/statics/images/screenshots/9.2.0/pl/mobile-screenshot-6-light@3x.png';
import MobileScreenshot7DarkPL from '../../public/statics/images/screenshots/9.2.0/pl/mobile-screenshot-7-dark@3x.png';
import MobileScreenshot7LightPL from '../../public/statics/images/screenshots/9.2.0/pl/mobile-screenshot-7-light@3x.png';
import DesktopScreenshot1DarkPL from '../../public/statics/images/screenshots/9.2.0/pl/desktop-screenshot-1-dark@3x.png';
import DesktopScreenshot1LightPL from '../../public/statics/images/screenshots/9.2.0/pl/desktop-screenshot-1-light@3x.png';
import DesktopScreenshot2DarkPL from '../../public/statics/images/screenshots/9.2.0/pl/desktop-screenshot-2-dark@3x.png';
import DesktopScreenshot2LightPL from '../../public/statics/images/screenshots/9.2.0/pl/desktop-screenshot-2-light@3x.png';
import DesktopScreenshot3DarkPL from '../../public/statics/images/screenshots/9.2.0/pl/desktop-screenshot-3-dark@3x.png';
import DesktopScreenshot3LightPL from '../../public/statics/images/screenshots/9.2.0/pl/desktop-screenshot-3-light@3x.png';
import DesktopScreenshot4DarkPL from '../../public/statics/images/screenshots/9.2.0/pl/desktop-screenshot-4-dark@3x.png';
import DesktopScreenshot4LightPL from '../../public/statics/images/screenshots/9.2.0/pl/desktop-screenshot-4-light@3x.png';
// import DesktopScreenshot5DarkPL from '../../public/statics/images/screenshots/9.2.0/pl/desktop-screenshot-5-dark@3x.png';
// import DesktopScreenshot5LightPL from '../../public/statics/images/screenshots/9.2.0/pl/desktop-screenshot-5-light@3x.png';
import DesktopScreenshot6DarkPL from '../../public/statics/images/screenshots/9.2.0/pl/desktop-screenshot-6-dark@3x.png';
import DesktopScreenshot6LightPL from '../../public/statics/images/screenshots/9.2.0/pl/desktop-screenshot-6-light@3x.png';
import DesktopScreenshot7DarkPL from '../../public/statics/images/screenshots/9.2.0/pl/desktop-screenshot-7-dark@3x.png';
import DesktopScreenshot7LightPL from '../../public/statics/images/screenshots/9.2.0/pl/desktop-screenshot-7-light@3x.png';

import MobileScreenshot1DarkPT from '../../public/statics/images/screenshots/9.2.0/pt/mobile-screenshot-1-dark@3x.png';
import MobileScreenshot1LightPT from '../../public/statics/images/screenshots/9.2.0/pt/mobile-screenshot-1-light@3x.png';
import MobileScreenshot2DarkPT from '../../public/statics/images/screenshots/9.2.0/pt/mobile-screenshot-2-dark@3x.png';
import MobileScreenshot2LightPT from '../../public/statics/images/screenshots/9.2.0/pt/mobile-screenshot-2-light@3x.png';
import MobileScreenshot3DarkPT from '../../public/statics/images/screenshots/9.2.0/pt/mobile-screenshot-3-dark@3x.png';
import MobileScreenshot3LightPT from '../../public/statics/images/screenshots/9.2.0/pt/mobile-screenshot-3-light@3x.png';
import MobileScreenshot4DarkPT from '../../public/statics/images/screenshots/9.2.0/pt/mobile-screenshot-4-dark@3x.png';
import MobileScreenshot4LightPT from '../../public/statics/images/screenshots/9.2.0/pt/mobile-screenshot-4-light@3x.png';
// import MobileScreenshot5DarkPT from '../../public/statics/images/screenshots/9.2.0/pt/mobile-screenshot-5-dark@3x.png';
// import MobileScreenshot5LightPT from '../../public/statics/images/screenshots/9.2.0/pt/mobile-screenshot-5-light@3x.png';
import MobileScreenshot6DarkPT from '../../public/statics/images/screenshots/9.2.0/pt/mobile-screenshot-6-dark@3x.png';
import MobileScreenshot6LightPT from '../../public/statics/images/screenshots/9.2.0/pt/mobile-screenshot-6-light@3x.png';
import MobileScreenshot7DarkPT from '../../public/statics/images/screenshots/9.2.0/pt/mobile-screenshot-7-dark@3x.png';
import MobileScreenshot7LightPT from '../../public/statics/images/screenshots/9.2.0/pt/mobile-screenshot-7-light@3x.png';
import DesktopScreenshot1DarkPT from '../../public/statics/images/screenshots/9.2.0/pt/desktop-screenshot-1-dark@3x.png';
import DesktopScreenshot1LightPT from '../../public/statics/images/screenshots/9.2.0/pt/desktop-screenshot-1-light@3x.png';
import DesktopScreenshot2DarkPT from '../../public/statics/images/screenshots/9.2.0/pt/desktop-screenshot-2-dark@3x.png';
import DesktopScreenshot2LightPT from '../../public/statics/images/screenshots/9.2.0/pt/desktop-screenshot-2-light@3x.png';
import DesktopScreenshot3DarkPT from '../../public/statics/images/screenshots/9.2.0/pt/desktop-screenshot-3-dark@3x.png';
import DesktopScreenshot3LightPT from '../../public/statics/images/screenshots/9.2.0/pt/desktop-screenshot-3-light@3x.png';
import DesktopScreenshot4DarkPT from '../../public/statics/images/screenshots/9.2.0/pt/desktop-screenshot-4-dark@3x.png';
import DesktopScreenshot4LightPT from '../../public/statics/images/screenshots/9.2.0/pt/desktop-screenshot-4-light@3x.png';
// import DesktopScreenshot5DarkPT from '../../public/statics/images/screenshots/9.2.0/pt/desktop-screenshot-5-dark@3x.png';
// import DesktopScreenshot5LightPT from '../../public/statics/images/screenshots/9.2.0/pt/desktop-screenshot-5-light@3x.png';
import DesktopScreenshot6DarkPT from '../../public/statics/images/screenshots/9.2.0/pt/desktop-screenshot-6-dark@3x.png';
import DesktopScreenshot6LightPT from '../../public/statics/images/screenshots/9.2.0/pt/desktop-screenshot-6-light@3x.png';
import DesktopScreenshot7DarkPT from '../../public/statics/images/screenshots/9.2.0/pt/desktop-screenshot-7-dark@3x.png';
import DesktopScreenshot7LightPT from '../../public/statics/images/screenshots/9.2.0/pt/desktop-screenshot-7-light@3x.png';

import MobileScreenshot1DarkSV from '../../public/statics/images/screenshots/9.2.0/sv/mobile-screenshot-1-dark@3x.png';
import MobileScreenshot1LightSV from '../../public/statics/images/screenshots/9.2.0/sv/mobile-screenshot-1-light@3x.png';
import MobileScreenshot2DarkSV from '../../public/statics/images/screenshots/9.2.0/sv/mobile-screenshot-2-dark@3x.png';
import MobileScreenshot2LightSV from '../../public/statics/images/screenshots/9.2.0/sv/mobile-screenshot-2-light@3x.png';
import MobileScreenshot3DarkSV from '../../public/statics/images/screenshots/9.2.0/sv/mobile-screenshot-3-dark@3x.png';
import MobileScreenshot3LightSV from '../../public/statics/images/screenshots/9.2.0/sv/mobile-screenshot-3-light@3x.png';
import MobileScreenshot4DarkSV from '../../public/statics/images/screenshots/9.2.0/sv/mobile-screenshot-4-dark@3x.png';
import MobileScreenshot4LightSV from '../../public/statics/images/screenshots/9.2.0/sv/mobile-screenshot-4-light@3x.png';
// import MobileScreenshot5DarkSV from '../../public/statics/images/screenshots/9.2.0/sv/mobile-screenshot-5-dark@3x.png';
// import MobileScreenshot5LightSV from '../../public/statics/images/screenshots/9.2.0/sv/mobile-screenshot-5-light@3x.png';
import MobileScreenshot6DarkSV from '../../public/statics/images/screenshots/9.2.0/sv/mobile-screenshot-6-dark@3x.png';
import MobileScreenshot6LightSV from '../../public/statics/images/screenshots/9.2.0/sv/mobile-screenshot-6-light@3x.png';
import MobileScreenshot7DarkSV from '../../public/statics/images/screenshots/9.2.0/sv/mobile-screenshot-7-dark@3x.png';
import MobileScreenshot7LightSV from '../../public/statics/images/screenshots/9.2.0/sv/mobile-screenshot-7-light@3x.png';
import DesktopScreenshot1DarkSV from '../../public/statics/images/screenshots/9.2.0/sv/desktop-screenshot-1-dark@3x.png';
import DesktopScreenshot1LightSV from '../../public/statics/images/screenshots/9.2.0/sv/desktop-screenshot-1-light@3x.png';
import DesktopScreenshot2DarkSV from '../../public/statics/images/screenshots/9.2.0/sv/desktop-screenshot-2-dark@3x.png';
import DesktopScreenshot2LightSV from '../../public/statics/images/screenshots/9.2.0/sv/desktop-screenshot-2-light@3x.png';
import DesktopScreenshot3DarkSV from '../../public/statics/images/screenshots/9.2.0/sv/desktop-screenshot-3-dark@3x.png';
import DesktopScreenshot3LightSV from '../../public/statics/images/screenshots/9.2.0/sv/desktop-screenshot-3-light@3x.png';
import DesktopScreenshot4DarkSV from '../../public/statics/images/screenshots/9.2.0/sv/desktop-screenshot-4-dark@3x.png';
import DesktopScreenshot4LightSV from '../../public/statics/images/screenshots/9.2.0/sv/desktop-screenshot-4-light@3x.png';
// import DesktopScreenshot5DarkSV from '../../public/statics/images/screenshots/9.2.0/sv/desktop-screenshot-5-dark@3x.png';
// import DesktopScreenshot5LightSV from '../../public/statics/images/screenshots/9.2.0/sv/desktop-screenshot-5-light@3x.png';
import DesktopScreenshot6DarkSV from '../../public/statics/images/screenshots/9.2.0/sv/desktop-screenshot-6-dark@3x.png';
import DesktopScreenshot6LightSV from '../../public/statics/images/screenshots/9.2.0/sv/desktop-screenshot-6-light@3x.png';
import DesktopScreenshot7DarkSV from '../../public/statics/images/screenshots/9.2.0/sv/desktop-screenshot-7-dark@3x.png';
import DesktopScreenshot7LightSV from '../../public/statics/images/screenshots/9.2.0/sv/desktop-screenshot-7-light@3x.png';

export type ScreenshotsName =
  | 'mobile-screenshot-1-dark'
  | 'mobile-screenshot-1-light'
  | 'mobile-screenshot-2-dark'
  | 'mobile-screenshot-2-light'
  | 'mobile-screenshot-3-dark'
  | 'mobile-screenshot-3-light'
  | 'mobile-screenshot-4-dark'
  | 'mobile-screenshot-4-light'
  // | 'mobile-screenshot-5-dark'
  // | 'mobile-screenshot-5-light'
  | 'mobile-screenshot-6-dark'
  | 'mobile-screenshot-6-light'
  | 'mobile-screenshot-7-dark'
  | 'mobile-screenshot-7-light'
  | 'desktop-screenshot-1-dark'
  | 'desktop-screenshot-1-light'
  | 'desktop-screenshot-2-dark'
  | 'desktop-screenshot-2-light'
  | 'desktop-screenshot-3-dark'
  | 'desktop-screenshot-3-light'
  | 'desktop-screenshot-4-dark'
  | 'desktop-screenshot-4-light'
  // | 'desktop-screenshot-5-dark'
  // | 'desktop-screenshot-5-light'
  | 'desktop-screenshot-6-dark'
  | 'desktop-screenshot-6-light'
  | 'desktop-screenshot-7-dark'
  | 'desktop-screenshot-7-light';
export const allScreenshots: Record<AvailableLanguages, Record<ScreenshotsName, StaticImageData>> = {
  de: {
    'mobile-screenshot-1-dark': MobileScreenshot1DarkDE,
    'mobile-screenshot-1-light': MobileScreenshot1LightDE,
    'mobile-screenshot-2-dark': MobileScreenshot2DarkDE,
    'mobile-screenshot-2-light': MobileScreenshot2LightDE,
    'mobile-screenshot-3-dark': MobileScreenshot3DarkDE,
    'mobile-screenshot-3-light': MobileScreenshot3LightDE,
    'mobile-screenshot-4-dark': MobileScreenshot4DarkDE,
    'mobile-screenshot-4-light': MobileScreenshot4LightDE,
    // 'mobile-screenshot-5-dark': MobileScreenshot5DarkDE,
    // 'mobile-screenshot-5-light': MobileScreenshot5LightDE,
    'mobile-screenshot-6-dark': MobileScreenshot6DarkDE,
    'mobile-screenshot-6-light': MobileScreenshot6LightDE,
    'mobile-screenshot-7-dark': MobileScreenshot7DarkDE,
    'mobile-screenshot-7-light': MobileScreenshot7LightDE,
    'desktop-screenshot-1-dark': DesktopScreenshot1DarkDE,
    'desktop-screenshot-1-light': DesktopScreenshot1LightDE,
    'desktop-screenshot-2-dark': DesktopScreenshot2DarkDE,
    'desktop-screenshot-2-light': DesktopScreenshot2LightDE,
    'desktop-screenshot-3-dark': DesktopScreenshot3DarkDE,
    'desktop-screenshot-3-light': DesktopScreenshot3LightDE,
    'desktop-screenshot-4-dark': DesktopScreenshot4DarkDE,
    'desktop-screenshot-4-light': DesktopScreenshot4LightDE,
    // 'desktop-screenshot-5-dark': DesktopScreenshot5DarkDE,
    // 'desktop-screenshot-5-light': DesktopScreenshot5LightDE,
    'desktop-screenshot-6-dark': DesktopScreenshot6DarkDE,
    'desktop-screenshot-6-light': DesktopScreenshot6LightDE,
    'desktop-screenshot-7-dark': DesktopScreenshot7DarkDE,
    'desktop-screenshot-7-light': DesktopScreenshot7LightDE
  },
  en: {
    'mobile-screenshot-1-dark': MobileScreenshot1DarkEN,
    'mobile-screenshot-1-light': MobileScreenshot1LightEN,
    'mobile-screenshot-2-dark': MobileScreenshot2DarkEN,
    'mobile-screenshot-2-light': MobileScreenshot2LightEN,
    'mobile-screenshot-3-dark': MobileScreenshot3DarkEN,
    'mobile-screenshot-3-light': MobileScreenshot3LightEN,
    'mobile-screenshot-4-dark': MobileScreenshot4DarkEN,
    'mobile-screenshot-4-light': MobileScreenshot4LightEN,
    // 'mobile-screenshot-5-dark': MobileScreenshot5DarkEN,
    // 'mobile-screenshot-5-light': MobileScreenshot5LightEN,
    'mobile-screenshot-6-dark': MobileScreenshot6DarkEN,
    'mobile-screenshot-6-light': MobileScreenshot6LightEN,
    'mobile-screenshot-7-dark': MobileScreenshot7DarkEN,
    'mobile-screenshot-7-light': MobileScreenshot7LightEN,
    'desktop-screenshot-1-dark': DesktopScreenshot1DarkEN,
    'desktop-screenshot-1-light': DesktopScreenshot1LightEN,
    'desktop-screenshot-2-dark': DesktopScreenshot2DarkEN,
    'desktop-screenshot-2-light': DesktopScreenshot2LightEN,
    'desktop-screenshot-3-dark': DesktopScreenshot3DarkEN,
    'desktop-screenshot-3-light': DesktopScreenshot3LightEN,
    'desktop-screenshot-4-dark': DesktopScreenshot4DarkEN,
    'desktop-screenshot-4-light': DesktopScreenshot4LightEN,
    // 'desktop-screenshot-5-dark': DesktopScreenshot5DarkEN,
    // 'desktop-screenshot-5-light': DesktopScreenshot5LightEN,
    'desktop-screenshot-6-dark': DesktopScreenshot6DarkEN,
    'desktop-screenshot-6-light': DesktopScreenshot6LightEN,
    'desktop-screenshot-7-dark': DesktopScreenshot7DarkEN,
    'desktop-screenshot-7-light': DesktopScreenshot7LightEN
  },
  es: {
    'mobile-screenshot-1-dark': MobileScreenshot1DarkES,
    'mobile-screenshot-1-light': MobileScreenshot1LightES,
    'mobile-screenshot-2-dark': MobileScreenshot2DarkES,
    'mobile-screenshot-2-light': MobileScreenshot2LightES,
    'mobile-screenshot-3-dark': MobileScreenshot3DarkES,
    'mobile-screenshot-3-light': MobileScreenshot3LightES,
    'mobile-screenshot-4-dark': MobileScreenshot4DarkES,
    'mobile-screenshot-4-light': MobileScreenshot4LightES,
    // 'mobile-screenshot-5-dark': MobileScreenshot5DarkES,
    // 'mobile-screenshot-5-light': MobileScreenshot5LightES,
    'mobile-screenshot-6-dark': MobileScreenshot6DarkES,
    'mobile-screenshot-6-light': MobileScreenshot6LightES,
    'mobile-screenshot-7-dark': MobileScreenshot7DarkES,
    'mobile-screenshot-7-light': MobileScreenshot7LightES,
    'desktop-screenshot-1-dark': DesktopScreenshot1DarkES,
    'desktop-screenshot-1-light': DesktopScreenshot1LightES,
    'desktop-screenshot-2-dark': DesktopScreenshot2DarkES,
    'desktop-screenshot-2-light': DesktopScreenshot2LightES,
    'desktop-screenshot-3-dark': DesktopScreenshot3DarkES,
    'desktop-screenshot-3-light': DesktopScreenshot3LightES,
    'desktop-screenshot-4-dark': DesktopScreenshot4DarkES,
    'desktop-screenshot-4-light': DesktopScreenshot4LightES,
    // 'desktop-screenshot-5-dark': DesktopScreenshot5DarkES,
    // 'desktop-screenshot-5-light': DesktopScreenshot5LightES,
    'desktop-screenshot-6-dark': DesktopScreenshot6DarkES,
    'desktop-screenshot-6-light': DesktopScreenshot6LightES,
    'desktop-screenshot-7-dark': DesktopScreenshot7DarkES,
    'desktop-screenshot-7-light': DesktopScreenshot7LightES
  },
  fr: {
    'mobile-screenshot-1-dark': MobileScreenshot1DarkFR,
    'mobile-screenshot-1-light': MobileScreenshot1LightFR,
    'mobile-screenshot-2-dark': MobileScreenshot2DarkFR,
    'mobile-screenshot-2-light': MobileScreenshot2LightFR,
    'mobile-screenshot-3-dark': MobileScreenshot3DarkFR,
    'mobile-screenshot-3-light': MobileScreenshot3LightFR,
    'mobile-screenshot-4-dark': MobileScreenshot4DarkFR,
    'mobile-screenshot-4-light': MobileScreenshot4LightFR,
    // 'mobile-screenshot-5-dark': MobileScreenshot5DarkFR,
    // 'mobile-screenshot-5-light': MobileScreenshot5LightFR,
    'mobile-screenshot-6-dark': MobileScreenshot6DarkFR,
    'mobile-screenshot-6-light': MobileScreenshot6LightFR,
    'mobile-screenshot-7-dark': MobileScreenshot7DarkFR,
    'mobile-screenshot-7-light': MobileScreenshot7LightFR,
    'desktop-screenshot-1-dark': DesktopScreenshot1DarkFR,
    'desktop-screenshot-1-light': DesktopScreenshot1LightFR,
    'desktop-screenshot-2-dark': DesktopScreenshot2DarkFR,
    'desktop-screenshot-2-light': DesktopScreenshot2LightFR,
    'desktop-screenshot-3-dark': DesktopScreenshot3DarkFR,
    'desktop-screenshot-3-light': DesktopScreenshot3LightFR,
    'desktop-screenshot-4-dark': DesktopScreenshot4DarkFR,
    'desktop-screenshot-4-light': DesktopScreenshot4LightFR,
    // 'desktop-screenshot-5-dark': DesktopScreenshot5DarkFR,
    // 'desktop-screenshot-5-light': DesktopScreenshot5LightFR,
    'desktop-screenshot-6-dark': DesktopScreenshot6DarkFR,
    'desktop-screenshot-6-light': DesktopScreenshot6LightFR,
    'desktop-screenshot-7-dark': DesktopScreenshot7DarkFR,
    'desktop-screenshot-7-light': DesktopScreenshot7LightFR
  },
  it: {
    'mobile-screenshot-1-dark': MobileScreenshot1DarkIT,
    'mobile-screenshot-1-light': MobileScreenshot1LightIT,
    'mobile-screenshot-2-dark': MobileScreenshot2DarkIT,
    'mobile-screenshot-2-light': MobileScreenshot2LightIT,
    'mobile-screenshot-3-dark': MobileScreenshot3DarkIT,
    'mobile-screenshot-3-light': MobileScreenshot3LightIT,
    'mobile-screenshot-4-dark': MobileScreenshot4DarkIT,
    'mobile-screenshot-4-light': MobileScreenshot4LightIT,
    // 'mobile-screenshot-5-dark': MobileScreenshot5DarkIT,
    // 'mobile-screenshot-5-light': MobileScreenshot5LightIT,
    'mobile-screenshot-6-dark': MobileScreenshot6DarkIT,
    'mobile-screenshot-6-light': MobileScreenshot6LightIT,
    'mobile-screenshot-7-dark': MobileScreenshot7DarkIT,
    'mobile-screenshot-7-light': MobileScreenshot7LightIT,
    'desktop-screenshot-1-dark': DesktopScreenshot1DarkIT,
    'desktop-screenshot-1-light': DesktopScreenshot1LightIT,
    'desktop-screenshot-2-dark': DesktopScreenshot2DarkIT,
    'desktop-screenshot-2-light': DesktopScreenshot2LightIT,
    'desktop-screenshot-3-dark': DesktopScreenshot3DarkIT,
    'desktop-screenshot-3-light': DesktopScreenshot3LightIT,
    'desktop-screenshot-4-dark': DesktopScreenshot4DarkIT,
    'desktop-screenshot-4-light': DesktopScreenshot4LightIT,
    // 'desktop-screenshot-5-dark': DesktopScreenshot5DarkIT,
    // 'desktop-screenshot-5-light': DesktopScreenshot5LightIT,
    'desktop-screenshot-6-dark': DesktopScreenshot6DarkIT,
    'desktop-screenshot-6-light': DesktopScreenshot6LightIT,
    'desktop-screenshot-7-dark': DesktopScreenshot7DarkIT,
    'desktop-screenshot-7-light': DesktopScreenshot7LightIT
  },
  nl: {
    'mobile-screenshot-1-dark': MobileScreenshot1DarkNL,
    'mobile-screenshot-1-light': MobileScreenshot1LightNL,
    'mobile-screenshot-2-dark': MobileScreenshot2DarkNL,
    'mobile-screenshot-2-light': MobileScreenshot2LightNL,
    'mobile-screenshot-3-dark': MobileScreenshot3DarkNL,
    'mobile-screenshot-3-light': MobileScreenshot3LightNL,
    'mobile-screenshot-4-dark': MobileScreenshot4DarkNL,
    'mobile-screenshot-4-light': MobileScreenshot4LightNL,
    // 'mobile-screenshot-5-dark': MobileScreenshot5DarkNL,
    // 'mobile-screenshot-5-light': MobileScreenshot5LightNL,
    'mobile-screenshot-6-dark': MobileScreenshot6DarkNL,
    'mobile-screenshot-6-light': MobileScreenshot6LightNL,
    'mobile-screenshot-7-dark': MobileScreenshot7DarkNL,
    'mobile-screenshot-7-light': MobileScreenshot7LightNL,
    'desktop-screenshot-1-dark': DesktopScreenshot1DarkNL,
    'desktop-screenshot-1-light': DesktopScreenshot1LightNL,
    'desktop-screenshot-2-dark': DesktopScreenshot2DarkNL,
    'desktop-screenshot-2-light': DesktopScreenshot2LightNL,
    'desktop-screenshot-3-dark': DesktopScreenshot3DarkNL,
    'desktop-screenshot-3-light': DesktopScreenshot3LightNL,
    'desktop-screenshot-4-dark': DesktopScreenshot4DarkNL,
    'desktop-screenshot-4-light': DesktopScreenshot4LightNL,
    // 'desktop-screenshot-5-dark': DesktopScreenshot5DarkNL,
    // 'desktop-screenshot-5-light': DesktopScreenshot5LightNL,
    'desktop-screenshot-6-dark': DesktopScreenshot6DarkNL,
    'desktop-screenshot-6-light': DesktopScreenshot6LightNL,
    'desktop-screenshot-7-dark': DesktopScreenshot7DarkNL,
    'desktop-screenshot-7-light': DesktopScreenshot7LightNL
  },
  pl: {
    'mobile-screenshot-1-dark': MobileScreenshot1DarkPL,
    'mobile-screenshot-1-light': MobileScreenshot1LightPL,
    'mobile-screenshot-2-dark': MobileScreenshot2DarkPL,
    'mobile-screenshot-2-light': MobileScreenshot2LightPL,
    'mobile-screenshot-3-dark': MobileScreenshot3DarkPL,
    'mobile-screenshot-3-light': MobileScreenshot3LightPL,
    'mobile-screenshot-4-dark': MobileScreenshot4DarkPL,
    'mobile-screenshot-4-light': MobileScreenshot4LightPL,
    // 'mobile-screenshot-5-dark': MobileScreenshot5DarkPL,
    // 'mobile-screenshot-5-light': MobileScreenshot5LightPL,
    'mobile-screenshot-6-dark': MobileScreenshot6DarkPL,
    'mobile-screenshot-6-light': MobileScreenshot6LightPL,
    'mobile-screenshot-7-dark': MobileScreenshot7DarkPL,
    'mobile-screenshot-7-light': MobileScreenshot7LightPL,
    'desktop-screenshot-1-dark': DesktopScreenshot1DarkPL,
    'desktop-screenshot-1-light': DesktopScreenshot1LightPL,
    'desktop-screenshot-2-dark': DesktopScreenshot2DarkPL,
    'desktop-screenshot-2-light': DesktopScreenshot2LightPL,
    'desktop-screenshot-3-dark': DesktopScreenshot3DarkPL,
    'desktop-screenshot-3-light': DesktopScreenshot3LightPL,
    'desktop-screenshot-4-dark': DesktopScreenshot4DarkPL,
    'desktop-screenshot-4-light': DesktopScreenshot4LightPL,
    // 'desktop-screenshot-5-dark': DesktopScreenshot5DarkPL,
    // 'desktop-screenshot-5-light': DesktopScreenshot5LightPL,
    'desktop-screenshot-6-dark': DesktopScreenshot6DarkPL,
    'desktop-screenshot-6-light': DesktopScreenshot6LightPL,
    'desktop-screenshot-7-dark': DesktopScreenshot7DarkPL,
    'desktop-screenshot-7-light': DesktopScreenshot7LightPL
  },
  pt: {
    'mobile-screenshot-1-dark': MobileScreenshot1DarkPT,
    'mobile-screenshot-1-light': MobileScreenshot1LightPT,
    'mobile-screenshot-2-dark': MobileScreenshot2DarkPT,
    'mobile-screenshot-2-light': MobileScreenshot2LightPT,
    'mobile-screenshot-3-dark': MobileScreenshot3DarkPT,
    'mobile-screenshot-3-light': MobileScreenshot3LightPT,
    'mobile-screenshot-4-dark': MobileScreenshot4DarkPT,
    'mobile-screenshot-4-light': MobileScreenshot4LightPT,
    // 'mobile-screenshot-5-dark': MobileScreenshot5DarkPT,
    // 'mobile-screenshot-5-light': MobileScreenshot5LightPT,
    'mobile-screenshot-6-dark': MobileScreenshot6DarkPT,
    'mobile-screenshot-6-light': MobileScreenshot6LightPT,
    'mobile-screenshot-7-dark': MobileScreenshot7DarkPT,
    'mobile-screenshot-7-light': MobileScreenshot7LightPT,
    'desktop-screenshot-1-dark': DesktopScreenshot1DarkPT,
    'desktop-screenshot-1-light': DesktopScreenshot1LightPT,
    'desktop-screenshot-2-dark': DesktopScreenshot2DarkPT,
    'desktop-screenshot-2-light': DesktopScreenshot2LightPT,
    'desktop-screenshot-3-dark': DesktopScreenshot3DarkPT,
    'desktop-screenshot-3-light': DesktopScreenshot3LightPT,
    'desktop-screenshot-4-dark': DesktopScreenshot4DarkPT,
    'desktop-screenshot-4-light': DesktopScreenshot4LightPT,
    // 'desktop-screenshot-5-dark': DesktopScreenshot5DarkPT,
    // 'desktop-screenshot-5-light': DesktopScreenshot5LightPT,
    'desktop-screenshot-6-dark': DesktopScreenshot6DarkPT,
    'desktop-screenshot-6-light': DesktopScreenshot6LightPT,
    'desktop-screenshot-7-dark': DesktopScreenshot7DarkPT,
    'desktop-screenshot-7-light': DesktopScreenshot7LightPT
  },
  sv: {
    'mobile-screenshot-1-dark': MobileScreenshot1DarkSV,
    'mobile-screenshot-1-light': MobileScreenshot1LightSV,
    'mobile-screenshot-2-dark': MobileScreenshot2DarkSV,
    'mobile-screenshot-2-light': MobileScreenshot2LightSV,
    'mobile-screenshot-3-dark': MobileScreenshot3DarkSV,
    'mobile-screenshot-3-light': MobileScreenshot3LightSV,
    'mobile-screenshot-4-dark': MobileScreenshot4DarkSV,
    'mobile-screenshot-4-light': MobileScreenshot4LightSV,
    // 'mobile-screenshot-5-dark': MobileScreenshot5DarkSV,
    // 'mobile-screenshot-5-light': MobileScreenshot5LightSV,
    'mobile-screenshot-6-dark': MobileScreenshot6DarkSV,
    'mobile-screenshot-6-light': MobileScreenshot6LightSV,
    'mobile-screenshot-7-dark': MobileScreenshot7DarkSV,
    'mobile-screenshot-7-light': MobileScreenshot7LightSV,
    'desktop-screenshot-1-dark': DesktopScreenshot1DarkSV,
    'desktop-screenshot-1-light': DesktopScreenshot1LightSV,
    'desktop-screenshot-2-dark': DesktopScreenshot2DarkSV,
    'desktop-screenshot-2-light': DesktopScreenshot2LightSV,
    'desktop-screenshot-3-dark': DesktopScreenshot3DarkSV,
    'desktop-screenshot-3-light': DesktopScreenshot3LightSV,
    'desktop-screenshot-4-dark': DesktopScreenshot4DarkSV,
    'desktop-screenshot-4-light': DesktopScreenshot4LightSV,
    // 'desktop-screenshot-5-dark': DesktopScreenshot5DarkSV,
    // 'desktop-screenshot-5-light': DesktopScreenshot5LightSV,
    'desktop-screenshot-6-dark': DesktopScreenshot6DarkSV,
    'desktop-screenshot-6-light': DesktopScreenshot6LightSV,
    'desktop-screenshot-7-dark': DesktopScreenshot7DarkSV,
    'desktop-screenshot-7-light': DesktopScreenshot7LightSV
  }
};
