import {config} from './configNextJS'

function normalizeSrc(src) {
  return src[0] === '/' ? src.slice(1) : src;
}

export default function imageLoader(options) {
  const {src, width, quality} = options;
  let normalizedSrc = normalizeSrc(src)
  let imgixHost = config.imgixHost
  if (src.startsWith('statics/')) {
    normalizedSrc = normalizedSrc.replace(/^statics\//, '')
    imgixHost = config.imgixStaticsHost
  } else {
    normalizedSrc = normalizedSrc.replace(/^_next\//, '')
    imgixHost = config.imgixHost
  }
  let url = '';
  if (!normalizedSrc.startsWith('https://')) {
    url = new URL(`https://${imgixHost}/${normalizedSrc}`);
  } else {
    url = new URL(`${normalizedSrc}`);
  }
  const params = url.searchParams;
  // auto params can be combined with comma separation, or reiteration
  params.set('auto', params.getAll('auto').join(',') || 'format');
  params.set('fit', params.get('fit') || 'max');
  params.set('w', params.get('w') || width.toString());
  if (quality) {
    params.set('q', quality.toString());
  }
  return url.href;
}